/* eslint-disable max-lines */
import { memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useBFContext } from '@context';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import {
	BASE_FRAME_NAMES,
	BUILD_FLOW_BACK_TEXT,
	BUILD_FLOW_NEXT_TEXT,
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	DEFAULT_BASE_FRAME_SHAPE,
	LARGE_WIDTH,
	SUBMISSION_METHODS,
	TOAST,
} from '@constants';
import { Button, Subtotal } from '@components';
import { useBaseFrameCartManager, useLocalStorage, useWindowDimensions } from '@utils/hooks';
import { useToastContext } from '@utils/context';
import { RX_TYPE } from '@utils/constants/base-skus';
import styles from './CartActions.module.scss';

const CartActions = ({ className = '' }: { className?: string }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setActiveFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', DEFAULT_BASE_FRAME_SHAPE);
	const { query } = useRouter();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isBF2AllTops = useFeatureIsOn('bf-2-all-tops');
	const { width: windowWidth } = useWindowDimensions();
	const { showToast } = useToastContext();
	const isEditMode = Boolean(query?.edit);
	const demo = query.demo as string;

	// Services
	const {
		step,
		setStepChange,
		frameColor,
		frame,
		journey,
		lensColor,
		rxType,
		readerRx,
		product,
		tops,
		subtotal,
		setStep,
		BaseFrameVariant,
		setLensPackage,
		lensPackage,
		setSelectedLenses,
		setRxType,
		setReaderRx,
		isSubscriptionActivated,
		subscriptionProduct,
		sellingPlanId,
		skipSubscription,
		setSkipSubscription,
		previousBaseFrames,
		pairCareItem,
	} = useBFContext();

	const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;

	// Data Tags and Step Info
	const isCollectionsStep = step === BUILD_FLOW_STEPS.TOP_FRAMES;
	const isPrescriptionsStep = step === BUILD_FLOW_STEPS.LENS;
	const isPackageStep = step === BUILD_FLOW_STEPS.PACKAGE;
	const isCustomizeStep = step === BUILD_FLOW_STEPS.CUSTOMIZE;
	const isSubmittedStep = step === BUILD_FLOW_STEPS.SUBMIT;
	const isSubscriptionStep = step === BUILD_FLOW_STEPS.SUBSCRIPTION;
	const isLensExpansionForm = (isCustomizeStep || isPrescriptionsStep || step === BUILD_FLOW_STEPS.PACKAGE) && !isSunglasses;

	const backDataTag = isPrescriptionsStep ? { 'data-exit-buildflow': true } : { 'data-back': true };
	const stepDataTag = isCollectionsStep && { 'data-add-to-cart': 'build-flow' };
	const isReaderStrengthRequired = (isSunglasses ? isPackageStep : isCustomizeStep) && !readerRx && rxType === RX_TYPE.READERS;
	const defaultNextText = !isSunglasses ? BUILD_FLOW_NEXT_TEXT[BUILD_FLOW_STEPS.LENS] : 'Make a Selection';
	let isNextDisabled =
		isSubmittedStep ||
		!rxType ||
		(!sellingPlanId && isSubscriptionStep && !isV2Subscription) ||
		(Object.keys(tops).length === 0 && isCollectionsStep && isSubscriptionActivated && isV2Subscription);

	if (!isSunglasses) isNextDisabled = isNextDisabled || !lensPackage;
	const shouldHideChevron =
		(isSubscriptionStep && !isV2Subscription) || (isCollectionsStep && isV2Subscription && isNextDisabled)
			? false
			: !(isSubmittedStep || isEditMode) && !(windowWidth < 1115 && windowWidth >= LARGE_WIDTH);

	const ATC = useBaseFrameCartManager({
		editingBundleKey: (query[BUNDLE_KEY_QUERY] as string) ?? null,
		demo,
		frame,
		frameColor,
		journey,
		lensColor,
		rxType,
		readerRx,
		product,
		tops,
		submissionMethod: SUBMISSION_METHODS.REMIND,
		BaseFrameVariant,
		lensPackage,
		subscriptionVariant: !isSubscriptionActivated || skipSubscription ? null : subscriptionProduct,
		sellingPlanId: sellingPlanId,
		isSubscriptionActivated,
		isBuildFlow: true,
		pairCareItem,
	});

	useEffect(() => {
		if (step !== BUILD_FLOW_STEPS.SUBMIT) return;
		ATC();
	}, [step, isBF2AllTops]);

	function handleStepNavigation({ type, currentStep = step }) {
		const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
		if (type === 'SUBMIT') {
			setStep(BUILD_FLOW_STEPS.SUBMIT);
			setActiveFrame(frame);
		}
		if (type === 'NEXT') {
			// This moves the BF steps to go to TOP FRAMES is the user has selected a lens package and rx type
			if (!!rxType && !!lensPackage && isLensExpansionForm && !isSunglasses) {
				setStep(
					isSubscriptionActivated && !isV2Subscription ? BUILD_FLOW_STEPS.SUBSCRIPTION : BUILD_FLOW_STEPS.TOP_FRAMES
				);
				return;
			}
			if (isSunglasses) {
				if (rxType === RX_TYPE.READERS) {
				} else {
					setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
					return;
				}
			}
			// since reader already preselected "standard" as lens package, we can skip the lens step
			if (rxType === RX_TYPE.READERS) {
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				return;
			}

			setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
		}
		if (type === 'CLOSE' || type === 'PREVIOUS') {
			if (currentStep === BUILD_FLOW_STEPS.TOP_FRAMES) {
				setSkipSubscription(false);
			}
			if (!isSunglasses) {
				if (isCustomizeStep) {
					setSelectedLenses([]);
					setLensPackage(null);

					if (rxType === RX_TYPE.READERS) {
						setStep(BUILD_FLOW_STEPS.LENS);
						setReaderRx(null);
						return;
					}
				}

				if (step === BUILD_FLOW_STEPS.PACKAGE) {
					setRxType(null);
				}
			}

			setStepChange(BUILD_FLOW_STEP_DIRECTION.PREV);
		}
	}

	function getBackButtonText(currentStep) {
		if (currentStep <= BUILD_FLOW_STEPS.LENS) {
			return BUILD_FLOW_BACK_TEXT.EXIT;
		}

		return BUILD_FLOW_BACK_TEXT.BACK;
	}

	function getNextButtonDataTag(currentStep: BUILD_FLOW_STEPS) {
		if (journey !== CUSTOMER_JOURNEYS.EYEGLASSES && isSunglasses && currentStep === BUILD_FLOW_STEPS.LENS) {
			return BUILD_FLOW_STEPS[BUILD_FLOW_STEPS.TOP_FRAMES];
		}
		if ((!isSubscriptionActivated || isV2Subscription) && currentStep + 1 === BUILD_FLOW_STEPS.SUBSCRIPTION) {
			return BUILD_FLOW_STEPS[currentStep + 2];
		}
		return BUILD_FLOW_STEPS[currentStep + 1];
	}

	function getNextButtonText(currentStep, isEditing) {
		if (isEditing && (currentStep === BUILD_FLOW_STEPS.TOP_FRAMES || (isBF2AllTops && !isSubscriptionActivated))) {
			return BUILD_FLOW_NEXT_TEXT.EDIT_MODE;
		}

		if (isBF2AllTops && !isSubscriptionActivated) {
			return BUILD_FLOW_NEXT_TEXT.BF_2_ALL_TOPS;
		}

		if (journey === CUSTOMER_JOURNEYS.SUNGLASSES && currentStep === BUILD_FLOW_STEPS.LENS) {
			return BUILD_FLOW_NEXT_TEXT[
				isSubscriptionActivated && !isV2Subscription ? BUILD_FLOW_STEPS.CUSTOMIZE : BUILD_FLOW_STEPS.SUBSCRIPTION
			];
		}

		if (!isSunglasses && !!rxType && !!lensPackage && isLensExpansionForm) {
			return BUILD_FLOW_NEXT_TEXT[
				isSubscriptionActivated && !isV2Subscription ? BUILD_FLOW_STEPS.CUSTOMIZE : BUILD_FLOW_STEPS.SUBSCRIPTION
			];
		}

		if (currentStep === BUILD_FLOW_STEPS.SUBSCRIPTION && isSubscriptionActivated && !isV2Subscription) {
			return BUILD_FLOW_NEXT_TEXT.ADDITIONAL_TOPS;
		}

		if (currentStep == BUILD_FLOW_STEPS.TOP_FRAMES && isNextDisabled && isV2Subscription) {
			return BUILD_FLOW_NEXT_TEXT.ONE_MORE_TOP;
		}

		return BUILD_FLOW_NEXT_TEXT[currentStep];
	}

	return (
		<>
			<div className={cn(styles['cart-actions'], className)}>
				<Subtotal subtotal={subtotal} />
				<div className={styles['button-container']}>
					<Button
						size='small'
						color='white'
						extraClasses={styles['prev']}
						disabled={isSubmittedStep}
						withChevron={true}
						chevronDirection='left'
						chevronPosition='front'
						label={getBackButtonText(step)}
						onClick={() =>
							handleStepNavigation({ type: isPrescriptionsStep ? 'CLOSE' : 'PREVIOUS', currentStep: step })
						}
						{...backDataTag}
					/>
					<Button
						size='small'
						color='green'
						fullWidth
						extraClasses={`${styles['next']}`}
						disabled={isNextDisabled}
						withChevron={shouldHideChevron}
						chevronDirection='right'
						label={rxType ? getNextButtonText(step, isEditMode) : defaultNextText}
						onClick={() =>
							isReaderStrengthRequired
								? showToast(TOAST.READERS_STRENGTH_ERROR)
								: handleStepNavigation({
										type:
											isCollectionsStep ||
											(isBF2AllTops &&
												!isSubscriptionActivated &&
												(isCustomizeStep || isEditMode || previousBaseFrames.length > 0)) ||
											(isBF2AllTops && isSunglasses)
												? 'SUBMIT'
												: 'NEXT',
									})
						}
						style={{ justifyContent: 'center' }}
						data-go-to-step={getNextButtonDataTag(step)}
						{...stepDataTag}
					/>
				</div>
			</div>
		</>
	);
};

CartActions.displayName = 'CartActions';

export default memo(CartActions);
